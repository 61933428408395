import '../style/footer.css'

const Footer = () => {
    return (
        <div className="FooterContainer">
            <div className='Develop'>
                Created By Dale Yu Using Create-React-App Bootstrap
            </div>
            <div className='Copyright'>
                © 2022 and beyond
            </div>
            <div className='Fill'>
                ff
            </div>
        </div>
    )
}

export default Footer