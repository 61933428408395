import "../style/research.css";
import {SiGithub} from 'react-icons/si'
import {SiFastapi} from 'react-icons/si'
import {AiFillFolderOpen} from 'react-icons/ai'
import SULI from '../Images/suli.png'
import POSTER from '../pdf_files/suli_nlp.pdf'

const Research = () => {
  return (
    <div className="Projects">
      <div className="TitleContainer">
        <div className="Title" id="research" >Research </div>
        <div className="c">Fill</div>
      </div>
     
      <div className="projectsContainer">
            <div className="Project">
                <div className="ProjectTextContainer">
                    <div className="ProjectTitle">
                      NLP at SULI <AiFillFolderOpen size="0.7em" />
                    </div>
                    <div className="ProjectDesc">
                        Using Python with NLTK, Gensim, and data visualization techniques, I was able
                        to analyze trends and classify millions of logbook entries. These entries were retrieved 
                        with MySQL queries. If you want more information on the training or code,
                        check out my jupyter notebook!
                    
                    </div>
                    <div className="ProjectButton">
                        <button  className ="DemoBTN"><a href= {POSTER} className = "PdfBTN"> My Poster  <SiFastapi className="Git" size="0.9em" /> </a> </button>
                        <button onClick={()=> window.open("https://github.com/daleyu/SULI", "_blank")} className="CodeBTN"> View Code <SiGithub className="Git" size="0.9em" /> </button>
                    </div>
                </div>
                <div className="ProjectIMGContainer">
                    <img className="ProjectIMG" src={SULI} alt="" />
                </div>
            </div>
      </div>
    </div>
  );
};

export default Research;
