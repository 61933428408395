import "../style/projects.css";
import "../style/skills.css";
import {FaReact} from 'react-icons/fa'
import {IoLogoJavascript} from 'react-icons/io'
import {FaPython} from 'react-icons/fa'
import {DiNodejs} from 'react-icons/di'
import {FaBootstrap} from 'react-icons/fa'
import {BsFillChatSquareTextFill} from 'react-icons/bs'
import {FaJava} from 'react-icons/fa'
import {SiC} from 'react-icons/si'
import {SiVisualstudiocode} from 'react-icons/si'
import {SiMysql} from 'react-icons/si'
import {FaGit} from 'react-icons/fa'
import {SiMicrosoftexcel} from 'react-icons/si'

const Skills = () => {
  return (
    <div className="SkillsMainContainer">
      <div className="TitleContainer">
        <div className="Title" id = "skills">What I Know:</div>
        <div className="c">Fill</div>
      </div>
      <div className="SkillsContainer">
        <div className="Coding">
          <div className="SubTitle">Coding Languages</div>
          <div className="SkillsBoxContainer">
            <div className="SkillsBox">
                <FaJava className="skill"/>
            </div>
            <div className="SkillsBox">
                <FaPython className="skill"/>
            </div>
            <div className="SkillsBox">
              <IoLogoJavascript className="skill"/>
            </div>
            <div className="SkillsBox">
                <SiC className="skill"/>
            </div>
            <div className="SkillsBox">
              <SiMysql className="skill"/>
            </div>

          </div>
        </div>
          <div className="Frameworks">
          <div className="SubTitle">Frameworks</div>
          <div className="SkillsBoxContainer">
            <div className="SkillsBox">
              <FaBootstrap className="skill"/>
            </div>
            <div className="SkillsBox">
                <BsFillChatSquareTextFill className="skill"/>
            </div>
            <div className="SkillsBox">
            <FaReact className="skill"/>
            </div>
            <div className="SkillsBox">
                <DiNodejs className="skill"/>
            </div>
          </div>
        </div>
        <div className="Tools">
          <div className="SubTitle">Tools/Services</div>
          <div className="SkillsBoxContainer">
            <div className="SkillsBox">
                <SiVisualstudiocode className="skill"/>
            </div>
            <div className="SkillsBox">
                <FaGit className="skill"/>
            </div>
            <div className="SkillsBox">
                <SiMicrosoftexcel className="skill"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;