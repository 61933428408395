import "../style/projects.css";
import '../style/contact.css'
import {FaLinkedinIn} from 'react-icons/fa'
import {AiFillGithub} from 'react-icons/ai'
import {BiMailSend} from 'react-icons/bi'
import {FaDiscord} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'


const Contact = () => {
  return (
    <div className="ContactMainContainer">
      <div className="TitleContainer">
        <div className="Title" id = "contact">Contact Me</div>
        <div className="c">Fill</div>
      </div>
      <div className="ContactContainer">
            <div className="ContactInfo">
                <div className="Find">
                    REACH OUT TO ME:
                </div>
                <div className="Links">
                    <div onClick={()=> window.open("https://www.linkedin.com/in/dalexyu/", "_blank")} className="Circle">
                        <FaLinkedinIn onClick={()=> window.open("https://www.linkedin.com/in/dalexyu/", "_blank")} className="Link"/>
                    </div>
                    <div onClick={()=> window.open("https://github.com/daleyu", "_blank")} className="Circle">
                        <AiFillGithub onClick={()=> window.open("https://github.com/daleyu", "_blank")} className="Link"/>
                    </div>
                    <div onClick={()=> window.open("mailto:yudale2003@gmail.com", "_blank")} className="Circle">
                        <BiMailSend onClick={()=> window.open("mailto:yudale2003@gmail.com", "_blank")} className="Link"/>
                    </div>
                    <div onClick={()=>window.open("https://discordapp.com/users/dahlia#1370")} className="Circle">
                        <FaDiscord onClick={()=> window.open("https://discordapp.com/users/dahlia#1370")} className ="Link"/>
                    </div>
                    <div onClick={()=>window.open("https://www.instagram.com/dalexyu")} className="Circle">
                        <FaInstagram onClick={()=> window.open("https://www.instagram.com/dalexyu")} className ="Link"/>
                    </div>
                    <div onClick={()=>window.open("https://facebook.com/dalexyu")} className="Circle">
                        <FaFacebook onClick={()=> window.open("https://facebook.com/dalexyu")} className ="Link"/>
                    </div>
               </div>
                <div className="Connect">
                    <div className="Me">
                        Feel Free to 
                    </div>
                    <div onClick={()=> window.open("https://www.linkedin.com/in/dalexyu/", "_blank")} className="ConnectLink">
                        Connect
                    </div>
                    <div className="Me">
                        with me
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Contact