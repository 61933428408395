import "../style/projects.css";
import {SiGithub} from 'react-icons/si'
import {SiFastapi} from 'react-icons/si'
import {SiHtml5} from 'react-icons/si'
import YUCO from "../Images/yuco.png"
import { SiNodedotjs } from "react-icons/si"
import MEMCAPSULE from "../Images/memcapsules.png"
import {BsTwitter} from 'react-icons/bs'
import TWITTER from "../Images/twitter_graphic.png"
import MEEBO from "../Images/meebo.png"

const Projects = () => {
  return (
    <div className="Projects">
      <div className="TitleContainer">
        <div className="Title" id = "projects">Project Portfolio</div>
        <div className="c">Fill</div>
      </div>
     
      <div className="projectsContainer">
            <div className="Project">
                <div className="ProjectTextContainer">
                    <div className="ProjectTitle">
                      Meebo <SiNodedotjs size="0.7em" />
                    </div>
                    <div className="ProjectDesc">
                        A web application that focused on giving kids a easier way to interact with generative AI 
                        and feed outputs that help with being more environmentally conscious.
                        This project was built with React, Next.js, Divinci AI, and Firebase.
                    </div>
                    <div className="ProjectButton">
                        <button onClick={()=> window.open("https://github.com/daleyu/HackCewit", "_blank")} className="CodeBTN"> View Code <SiGithub className="Git" size="0.9em" /> </button>
                    </div>
                </div>
                <div className="ProjectIMGContainer">
                    <img className="ProjectIMG" src={MEEBO} alt="" />
                </div>
            </div>
            <div className="Project">
                <div className="ProjectTextContainer">
                    <div className="ProjectTitle">
                      MemCapsules <SiNodedotjs size="0.7em" />
                    </div>
                    <div className="ProjectDesc">
                        A web application that allows users to create and share memory capsules.
                        Users can create capsules that are delivered at a set time and transcribed with AI
                        This project was built with React, Next.js, SQLite, AssemblyAI, Twilio API
                    </div>
                    <div className="ProjectButton">
                        <button onClick={()=> window.open("https://devpost.com/software/memcapsules", "_blank")} className="DemoBTN"> More Info  ✉️ </button>
                        <button onClick={()=> window.open("https://memcapsules.vercel.app", "_blank")} className="DemoBTN">Live Demo  <SiFastapi className="Git" size="0.9em" /> </button> 
                        <button onClick={()=> window.open("https://github.com/daleyu/memcapsules", "_blank")} className="CodeBTN"> View Code <SiGithub className="Git" size="0.9em" /> </button>
                    </div>
                </div>
                <div className="ProjectIMGContainer">
                    <img className="ProjectIMG" src={MEMCAPSULE} alt="" />
                </div>
            </div>
            <div className="Project">
                <div className="ProjectTextContainer">
                    <div className="ProjectTitleLong">
                        Twitter Sentiment Analysis <BsTwitter size="0.7em" />
                    </div>
                    <div className="ProjectDesc">
                        Analyzed 1,600,000 Twitter comments and classify them as being positive, negative, neutral sentiment
                        • Developed a TF-IDF NaiveBayes Classifier using NLTK and SKLearn.
                    </div>
                    <div className="ProjectButton">
                        <button onClick={()=> window.open("https://github.com/daleyu/twitter_nlp", "_blank")} className="CodeBTN"> View Code <SiGithub className="Git" size="0.9em" /> </button>
                    </div>
                </div>
                <div className="ProjectIMGContainer">
                    <img className="ProjectIMG" src={TWITTER} alt="" />
                </div>
            </div>
            <div className="Project">
                <div className="ProjectTextContainer">
                    <div className="ProjectTitle">
                        YucoChimney <SiHtml5 size="0.7em" />
                    </div>
                    <div className="ProjectDesc">
                        Using HTML5 and CSS, I built a website for my small 
                        family business. It lists the products and allows customers to
                        be sent to the associated ebay selling page, or create a cart for purchase on site. 
                    
                    </div>
                    <div className="ProjectButton">
                        <button onClick={()=> window.open("https://www.yucochimney.com", "_blank")} className="DemoBTN">Live Demo  <SiFastapi className="Git" size="0.9em" /> </button> 
                        <button onClick={()=> window.open("https://github.com/daleyu", "_blank")} className="CodeBTN"> View Code <SiGithub className="Git" size="0.9em" /> </button>
                    </div>
                </div>
                <div className="ProjectIMGContainer">
                    <img className="ProjectIMG" src={YUCO} alt="" />
                </div>
            </div>
      </div>
    </div>
  );
};

export default Projects;
