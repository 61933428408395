import '../style/header.css'
import HeadLogo from './headerLogo'
import RESUME from "../pdf_files/dale_yu_2022.pdf";

const Header = () => {
    return (
        <div className="Header">
            <div className="HeaderLogo">
                <div className="Logo">
                    <HeadLogo/>
                </div>
            </div>
            <div className="Navigation">
                <h2 className="Nav">
                    <a className='ignored' href= "#about"> About Me</a>
                </h2>
                <h2 className='Nav'>
                    <a className='ignored' href= "#projects"> Projects </a>
                </h2>
                <h2 className = 'Nav'>
                    <a className='ignored' href= "#research"> Research </a>
                </h2>
                <h2 className='Nav'>
                    <a className='ignored' href="#skills">Skill Set</a> 
                </h2>
                <h2 className = 'Nav'>
                    <a className = 'ignored' href = {RESUME}> Resume</a>
                </h2>
                <h2 className='Nav'>
                    <a className='ignored' href="#contact">Contact Me</a>
                </h2> 
                
            </div>
        </div>
    )
} 

export default Header