import "../style/intro.css";
import React,  {useEffect} from 'react'

const IntroLogo = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="IntroBG" id="disappear">
      <div className="logoContainer">
        <svg
          id="introLogo"
          width="204"
          height="141"
          viewBox="0 0 204 141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 26 0 L 54 56 L 81 0 H 89 L 59 62 L 59 139 H 50 L 49 62 L 18 0 Z"
            stroke="#DFD498"
            stroke-width={2}
          />
          <path
            d="M 137 140 L 137 134 C 184 115 185 90 187 83 C 191 52 186 42 183 35 C 175 13 157 10 141 8 H 133 V 140 H 125.98 V 1 C 139 2 161 -1 177 15 C 189 32 192 32 194 65 C 194 100 180 125 137 140 Z"
            stroke="#DFD498"
            stroke-width={2}
          />
        
        </svg>
      </div>
    </div>
  );
};

export default IntroLogo;