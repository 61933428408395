import "../style/hero.css"
import Memoji from "../Images/dale.png"

const Hero = () => {
    return (
        <div className="HeroContainer" id = "about">
            <div className="HeroTextContainer">
                <div className = "Greeting">
                    Hello!
                </div>
                <div className="APContainer">
                    <div className="IM">
                        MY NAME IS
                    </div>
                    <div className="DALE">
                        DALE YU
                    </div>
                </div>
                <div className="About">
                    I'm a 2nd year at Columbia University studying Computer Science and East Asian Studies. 
                    I have great interest in Front-end development, Machine Learning Research, Artificial Inteligence 
                    Research with Natural Language Processing, and anything in between. I enjoy running, physics, 
                    art, exercise, and working on random craft projects.
                </div>
            </div>
            <div className="EmojiContainer">
                <img className="Memoji" src={Memoji} alt="" />
            </div>
        </div>
    )
}

export default Hero