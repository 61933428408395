import Logo from "./components/introLogo";
import Header from "./components/header"
import Hero from "./components/hero"
import Project from './components/projects'
import Research from './components/research'
import Skills from './components/skills'
import Contact from './components/contact'
import "./style/background.css";
import Footer from "./components/footer";


function App() {
  return (
    <div className="App">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <Logo />
      <Header/>
      <Hero/>
      <Project/>
      <Research/>
      <Skills/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;